@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url(./assets/font/Inter.ttf);
}

body {
  font-family: Inter, sans-serif !important;
  overflow: hidden;
}

.scrol {
  scrollbar-color: rgba(0, 0, 0, 0.22) #f1f1f1;
  scrollbar-width: thin;
}

@media only screen and (max-width: 1200px) {
  body {
    overflow: auto;
  }
}

.sidebarOpen {
  width: 65px;
  transition: width 0.3s;
}

.sidebarClose {
  width: 0px;
  transition: width 0.3s;
}

.mobSidebarOpen {
  width: 100%;
  transition: width 0.3s;
}

.mobSidebarClose {
  width: 0px;
  transition: width 0.3s;
}

.addModalOpen {
  width: 80%;
  transition: width 0.5s;
}

.addModalClose {
  width: 0px;
  transition: width 0.5s;
}

.addInvoiceOpen {
  width: 120%;
  height: 74vh;
  transition: width 0.5s;
}

.detailInvoiceHeight {
  height: 74vh;
}

.addInvoiceClose {
  width: 0px;
  transition: width 0.5s;
}

.tablesViewOpen {
  width: 100%;
}

.tablesViewClose {
  width: 0px;
  transition: width 0.5s;
}

.addModalCloseHandle {
  width: 0px;
}

.addModalHandle {
  width: 4%;
  position: absolute;
}

.detailsModalOpen {
  width: 80%;
  transition: width 0.5s;
}

.detailsModalClose {
  width: 0px;
  transition: width 0.5s;
}

.projectDetailsModalOpen {
  width: 100%;
  transition: width 0.5s;
}

.projectDetailsModalClose {
  width: 0px;
  transition: width 0.5s;
}

.projectTableModalOpen {
  width: 100%;
  transition: width 0.5s;
}

.contactsOpen {
  width: 33.33%;
  transition: width 0.5s;
}

.contactsClose {
  width: 0px;
  transition: width 0.5s;
}

.chatsFull {
  width: 100%;
  transition: width 0.5s;
}

.chatsHalf {
  width: 66.66%;
  transition: width 0.5s;
}

.projectTableModalClose {
  width: 0px;
  transition: width 0.5s;
}

.detailsInvoiceModalOpen {
  width: 120%;
  transition: width 0.5s;
}

.detailsInvoiceModalClose {
  width: 0px;
  transition: width 0.5s;
}

.sendInvoiceModalOpen {
  width: 110%;
  transition: width 0.5s;
}

.sendInvoiceModalClose {
  width: 0px;
  transition: width 0.5s;
}

.projectGrid {
  display: grid;
  grid-template-columns: 55% 45%;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00a1e4 !important;
}

.ant-pagination-options {
  margin-right: 20px !important;
}

.ant-btn-primary {
  background-color: #00a1e4 !important;
}

.MuiFilledInput-root {
  background: white !important;
  padding-right: 0px !important;
}

.MuiFilledInput-input {
  padding-left: 0px !important;
}

.MuiInput-root {
  font-size: 15px !important;
}

.MuiInputLabel-root {
  font-size: 15px !important;
}

.dateDiv .MuiInputLabel-root {
  left: -12px !important;
}

.progress {
  background: linear-gradient(to right, #4d9a19 70%, #d9d9d9 30%);
}

._2eZzQ {
  border-right: none !important;
}

.editAble {
  cursor: pointer;
}

.editAble:hover {
  background-color: #ececec;
}

.ant-table-column-title {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.fc-h-event .fc-event-title-container {
  padding: 4px !important;
}

.fc-direction-ltr {
  height: 89vh !important;
  margin-bottom: 16px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  display: flex !important;
  align-items: center !important;
}

.fc .fc-toolbar-title {
  font-size: 20px !important;
}

.fc .fc-button-primary {
  height: 36px !important;
}


.secure-accoutn-card {
  border: 1px dashed #00A1E4;
  border-radius: 4px;
  background-color: #00a0e413;
}

.invitations-card {
  border-bottom: 1px dashed rgb(170, 170, 170);
}

.invitations-section {
  height: 450px;
  overflow: auto;
}

.peer:checked~.peer-checked\:bg-blue-600 {
  background-color: #00A1E4 !important;
}

.border-dashed {
  border: 1px dashed rgb(170, 170, 170);
}

.fw-500 {
  font-weight: 500;
}

.fs-14 {
  font-size: 14px !important;
}

.ant-picker-range {
  border: none !important;
  border-bottom: 1px solid rgb(148, 147, 147) !important;
  border-radius: 0px !important;
  width: 250px !important;
}

.ant-picker-range:focus {
  box-shadow: 0px !important;
  border: none !important;
}

.css9u3bzj6-MuiFormControl-root-MuiTextField-root {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  width: 100% !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  height: 25px !important;
}

@media screen and (max-width: 642px) {
  #overView-detail {
    margin-top: 30px !important;
  }

  #edit-profile-form {
    margin-top: 350px !important;
  }
}

.status .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  color: blue !important;
}

.priority .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  color: red !important;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.22);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.pipeline .ant-table-wrapper .ant-table-tbody>tr>td{
  padding: 0px !important;
}

@media only screen and (min-width: 1800px) {
  .addInvoiceOpen {
    height: 80vh;
  }

  .detailInvoiceHeight {
    height: 80vh;
  }
}

@media only screen and (min-width: 2400px) {
  .addInvoiceOpen {
    height: 86vh;
  }

  .detailInvoiceHeight {
    height: 86vh;
  }
}